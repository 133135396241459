import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import * as styles from "../styles/pages/contact.module.css";

const Geo = ({ className }) => {
  return <img className={className} src="/SVG/map.svg" alt="" />;
};
const Mail = ({ className }) => {
  return <img className={className} src="/SVG/envelope.svg" alt="" />;
};
const Call = ({ className }) => {
  return <img className={className} src="/SVG/phone.svg" alt="" />;
};

const Contact = ({ data }) => {
  return (
    <Layout
      title={data.strapiSeo.PageTitle}
      description={data.strapiSeo.PageDescription}
    >
      <div className={styles.container}>
        <div className={styles.head}>
          <div className={styles.headInfo}>
            <h2>Contact Us</h2>
            <p>{data.strapiContactpage.Introduction}</p>
          </div>

          <GatsbyImage
            className={styles.bg}
            image={
              data.strapiContactpage.background.childImageSharp.gatsbyImageData
            }
            alt=""
          />
        </div>
        <div className={styles.body} styles={{ zIndex: 2 }}>
          <div>
            <Geo className={styles.icon} />
            <p style={{ textAlign: "center" }}>
              {data.strapiContactpage.Location}
            </p>
          </div>
          <div>
            <Mail className={styles.icon} />
            <p style={{ textAlign: "center" }}>
              {data.strapiContactpage.Email}
            </p>
          </div>
          <div>
            <Call className={styles.icon} />
            <p style={{ textAlign: "center" }}>
              {data.strapiContactpage.Phone}
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Contact;

export const query = graphql`
  query contactPage {
    strapiContactpage {
      Introduction
      Email
      Location
      Phone
      background {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
    strapiSeo(NameOfPage: { eq: "contact" }) {
      PageDescription
      PageTitle
    }
  }
`;
